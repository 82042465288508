import axios from 'axios'

import { BASE_URL } from '@constants'

export const api = axios.create({
  baseURL: BASE_URL
})

export const setDefaultAuthJwt = (jwt: string): void => {
  api.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
  api.interceptors.response.use(response => {
    return response
  }, error => {
    if (error?.response?.status === 401) {
      console.log('Unauthenticated')
    }
    return Promise.reject(error)
  })
}
