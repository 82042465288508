import { createStore, createTypedHooks, EasyPeasyConfig } from 'easy-peasy'

import { ClientModel, client } from './client'
import { PromoModel, promo } from './promo'
import { OutletModel, outlet } from './outlet'

export * from './client'
export * from './promo'
export * from './outlet'

export interface StoreModel {
  client: ClientModel,
  promo: PromoModel,
  outlet: OutletModel
}

const storeModel: StoreModel = {
  client,
  promo,
  outlet
}

const storeConfig: EasyPeasyConfig = {
  devTools: process.env.NODE_ENV !== 'production'
}

const store = createStore(storeModel, storeConfig)
const typedHooks = createTypedHooks<StoreModel>()

export const { useStoreActions, useStoreDispatch, useStoreState } = typedHooks
export default store
