import { FC, useEffect, useRef } from 'react'
import Slider from 'react-slick'

import { useStoreActions, useStoreState } from '@stores'
import { CLIENT_CODE } from '@constants'

export const Stores: FC = () => {
  const { fetchAllOutlet } = useStoreActions(action => action.outlet)
  const { outlets } = useStoreState(state => state.outlet)

  useEffect(() => {
    fetchAllOutlet({
      page: 1,
      limit: 100,
      client_code: CLIENT_CODE
    })
  }, [fetchAllOutlet])

  const sliderRef = useRef<Slider>(null)

  const setting = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    cssEase: 'ease-in-out',
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          centerMode: true
        }
      }
    ]
  }

  return (
    <section id="stores" className="mt-20 max-sm:mt-4 px-10 max-sm:px-4 py-20 max-lg:py-10 bg-primary overflow-x-hidden">
      <div className="max-w-screen-xl w-full mx-auto">
        <div className="font-madcoco text-5xl max-sm:text-3xl max-md:text-center text-white">OUR STORES</div>
        <div className="text-center mt-10 max-sm:mt-5">
          <div className="text-white text-2xl max-sm:text-xl font-semibold">
            Don't Get Mad, Drink  <span className="font-madcoco text-4xl max-sm:text-3xl">MADCOCO</span>!
          </div>
          <div className="text-white text-2xl max-sm:text-xl font-semibold">
            25+ Stores all over Indonesia
          </div>
          <div className="text-white text-2xl max-sm:text-xl font-semibold">
            Refreshing Ice Blended Coconut Drinks
          </div>
        </div>
        {outlets.filter(item => item.image).length > 0 &&
          <div className="relative mt-10 max-sm:mt-5 max-sm:-mx-10">
            <Slider
              ref={sliderRef}
              {...setting}
            >
              {outlets.filter(item => item.image).map((item, index) =>
                <div key={index} className="p-2">
                  <div className="group relative bg-white rounded-2xl border-4 border-white overflow-hidden">
                    <div
                      style={{ 'backgroundImage': `url(${item.image})` }}
                      className="aspect-square bg-cover bg-center overflow-hidden transition-all ease-in duration-200 group-hover:scale-105"
                    />
                    <div className="absolute bottom-0 left-0 w-full p-2 py-3 text-sm font-semibold text-center truncate bg-white translate-y-20 transition-all ease-out duration-200 group-hover:translate-y-0">
                      {item.name}
                    </div>
                  </div>
                </div>
              )}
            </Slider>
            <div
              className="absolute top-1/2 left-0 -translate-x-full -translate-y-1/2 opacity-80 hover:opacity-100 cursor-pointer z-10"
              onClick={() => sliderRef.current?.slickPrev()}
            >
              <img
                className="w-8 h-8"
                src={require('@assets/icons/chevron-left.svg').default}
                alt="madcoco.id"
              />
            </div>
            <div
              className="absolute top-1/2 right-0 translate-x-full -translate-y-1/2 opacity-80 hover:opacity-100 cursor-pointer z-10"
              onClick={() => sliderRef.current?.slickNext()}
            >
              <img
                className="w-8 h-8"
                src={require('@assets/icons/chevron-right.svg').default}
                alt="madcoco.id"
              />
            </div>
          </div>
        }
      </div>
    </section>
  )
}