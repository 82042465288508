import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { StoreProvider } from 'easy-peasy'
import ReactGA from 'react-ga'

import { Home } from '@pages'
import stores from '@stores'

import reportWebVitals from './reportWebVitals'
import './index.scss'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '*',
    element: <div className="flex justify-center items-center h-dvh text-5xl text-primary">404</div>
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <StoreProvider store={stores}>
      <RouterProvider router={router} />
    </StoreProvider>
  </React.StrictMode>
)

ReactGA.initialize('UA-106050962-1')
ReactGA.pageview(window.location.pathname + window.location.search)
reportWebVitals()