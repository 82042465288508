import { FC } from 'react'

export const Banner: FC = () => {
  return (
    <section id="home" className="px-10 max-sm:px-4 max-sm:mt-14">
      <div className="flex max-sm:flex-wrap justify-between gap-5 max-w-screen-xl w-full mx-auto mt-28 max-sm:mt-10">
        <div className="basis-1/2 max-sm:basis-full rounded-3xl max-sm:rounded-2xl overflow-hidden">
          <img
            className="w-full"
            src={require('@assets/images/banner-1.jpeg')}
            alt="madcoco.id"
          />
        </div>
        <div className="flex gap-5 max-sm:gap-0 basis-1/2 max-sm:basis-full">
          <div className="basis-1/2 rounded-3xl max-sm:rounded-2xl max-sm:rounded-r-none overflow-hidden">
            <img
              className="w-full h-1/2"
              src={require('@assets/images/banner-2.jpeg')}
              alt="madcoco.id"
            />
            <img
              className="w-full h-1/2"
              src={require('@assets/images/banner-3.jpeg')}
              alt="madcoco.id"
            />
          </div>
          <div className="basis-1/2 rounded-3xl max-sm:rounded-2xl max-sm:rounded-l-none overflow-hidden">
            <img
              className="w-full h-1/2"
              src={require('@assets/images/banner-4.jpeg')}
              alt="madcoco.id"
            />
            <img
              className="w-full h-1/2"
              src={require('@assets/images/banner-5.jpeg')}
              alt="madcoco.id"
            />
          </div>
        </div>
      </div>
    </section>
  )
}