import { FC } from 'react'

export const Footer: FC = () => {
  return (
    <footer>
      <div
        className="bg-primary text-center py-5 cursor-pointer"
        onClick={() => window.open('https://loyal.id')}
      >
        <div className="text-md text-white">Powered By</div>
        <img
          className="block mx-auto h-8 mt-2"
          src={require('@assets/images/loyalid-white.png')}
          alt="loyal.id"
        />
      </div>
    </footer>
  )
}