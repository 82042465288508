import { FC, useEffect } from 'react'

import { useStoreActions, useStoreState } from '@stores'
import { CLIENT_CODE } from '@constants'

export const Contact: FC = () => {
  const { fetchAllClient } = useStoreActions(action => action.client)
  const { clients } = useStoreState(state => state.client)

  useEffect(() => {
    fetchAllClient()
  }, [fetchAllClient])

  const CONTACT_NUMBER = clients.find(client => client.code === CLIENT_CODE)?.whatsapp_number?.replace(/[-+ ]/g, '') || ''

  return (
    <section id="contact" className="max-md:mt-4 px-10 max-md:px-4 py-20 max-md:py-10">
      <div className="max-w-screen-xl w-full mx-auto">
        <div className="font-madcoco text-5xl max-sm:text-3xl text-primary max-md:text-center">CONTACT US</div>
        <div className="flex justify-between max-md:flex-wrap mt-10">
          <div className="flex-1 max-w-[800px] max-md:basis-full">
            <div className="flex items-center max-md:flex-wrap">
              <img
                className="block h-36 max-md:h-32 mx-auto"
                src={require('@assets/images/logo.png')}
                alt="madcoco.id"
              />
              <div className="px-10 max-md:mt-10">
                <div className="font-bold text-xl text-primary max-md:text-center">PT RASA PENUH CERITA</div>
                <div className="mt-2 text-md font-medium text-primary max-md:text-center">
                  Gedung Ad Premier Office Park Lt. 9, Jl. TB. Simatupang No. 5,
                  Desa/Kelurahan Ragunan, Kec. Pasar Minggu, Kota Adm. Jakarta
                  Selatan, Provinsi DKI Jakarta
                </div>
                <div className="mt-2 text-md font-medium text-primary max-md:text-center">Kode Pos: 12550</div>
                <div className="mt-2 text-md font-medium text-primary max-md:text-center">email : <a href="mailto:hello@madcocoid.co">hello@madcocoid.co</a></div>
              </div>
            </div>
          </div>
          <div className="max-md:mt-10 contents max-md:block max-md:mx-auto">
            <div className="max-md:flex max-md:gap-5">
              <div className="flex items-center gap-4 cursor-pointer" onClick={() => window.open(`https://wa.me/${CONTACT_NUMBER}`)}>
                <img
                  className="h-10"
                  src={require('@assets/icons/whatsapp.svg').default}
                  alt="madcoco.id"
                />
                <div className="text-md font-semibold text-primary max-md:hidden">Customer Service</div>
              </div>
              <div className="flex items-center gap-4 py-2 cursor-pointer" onClick={() => window.open('https://instagram.com/madcoco.id')}>
                <img
                  className="h-10"
                  src={require('@assets/icons/instagram.svg').default}
                  alt="madcoco.id"
                />
                <div className="text-md font-semibold text-primary max-md:hidden">instagram.com/madcoco.id</div>
              </div>
              <div className="flex items-center gap-4 py-2 cursor-pointer" onClick={() => window.open('https://www.tiktok.com/@madcoco.id')}>
                <img
                  className="h-10"
                  src={require('@assets/icons/tiktok.svg').default}
                  alt="madcoco.id"
                />
                <div className="text-md font-semibold text-primary max-md:hidden">tiktok.com/@madcoco.id</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}