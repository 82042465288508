import { Fragment, FC } from 'react'

import { Footer, Topbar } from '@components'

import { Banner, Contact, Stores } from './components'

export const Home: FC = () => {
  return (
    <Fragment>
      <Topbar />
      <Banner />
      <Stores />
      <Contact />
      <Footer />
    </Fragment >
  )
}