import { FC, useEffect, useState } from 'react'

interface Props {
  scrolled?: boolean
}

export const Topbar: FC<Props> = ({ scrolled }) => {
  const [offsetTop, setOffSetTop] = useState<boolean>(false)
  const [showMenu, setShowMenu] = useState<boolean>(false)

  const onScroll = () => {
    setOffSetTop(window.scrollY > 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  }, [])

  const scrollto = (name: string) => {
    const section = document.querySelector(name)
    if (section) section.scrollIntoView({ behavior: 'smooth', block: 'start' })
    setShowMenu(false)
  }

  return (
    <header className={`header px-10 max-sm:px-4 fixed w-full top-0 bg-background z-50 transition-all ease-linear ${offsetTop ? 'py-5 max-sm:py-4 shadow-md' : 'py-10 max-sm:py-4'}`}>
      <div className="flex flex-wrap justify-between max-w-screen-xl w-full mx-auto">
        <nav className="brand max-sm:w-full">
          <img
            className="logo block h-8 max-md:h-6 max-sm:h-5 max-sm:mx-auto"
            src={require('@assets/images/madcoco.png')}
            alt="madcoco.id"
          />
        </nav>
        <nav className="menu flex gap-6 max-sm:hidden">
          <span className="font-madcoco text-2xl text-primary cursor-pointer" onClick={() => scrollto('#home')}>HOME</span>
          <span className="font-madcoco text-2xl text-primary cursor-pointer" onClick={() => scrollto('#stores')}>STORE</span>
          <span className="font-madcoco text-2xl text-primary cursor-pointer" onClick={() => scrollto('#contact')}>CONTACT US</span>
        </nav>
        <div
          className="absolute top-1/2 right-5 -translate-y-1/2 hidden max-sm:flex max-sm:flex-col gap-1"
          onClick={() => setShowMenu(!showMenu)}
        >
          <span className={`block w-6 h-1 bg-primary transition-all ease-in-out duration-300 origin-top-left ${showMenu ? 'rotate-45' : 'rotate-0'}`} />
          <span className={`block w-6 h-1 bg-primary transition-all ease-in-out duration-300 ${showMenu ? 'opacity-0' : 'opacity-100'}`} />
          <span className={`block w-6 h-1 bg-primary transition-all ease-in-out duration-300 origin-bottom-left ${showMenu ? '-rotate-45' : 'rotate-0'}`} />
        </div>
      </div>
      <div className={`absolute top-full left-0 w-full bg-background shadow-md transition-all ease-in-out duration-500 overflow-hidden hidden max-sm:block ${showMenu ? 'max-h-80' : 'max-h-0'}`}>
        <nav className="menu flex flex-col gap-2 p-5">
          <span className="font-madcoco text-2xl text-primary cursor-pointer" onClick={() => scrollto('#home')}>HOME</span>
          <span className="font-madcoco text-2xl text-primary cursor-pointer" onClick={() => scrollto('#stores')}>STORE</span>
          <span className="font-madcoco text-2xl text-primary cursor-pointer" onClick={() => scrollto('#contact')}>CONTACT US</span>
        </nav>
      </div>
    </header>
  )
}