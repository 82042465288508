import { action, thunk } from 'easy-peasy'

import { FetchStatus, Paginator } from '@types'

import { OutletModel, outletService } from '.'

export const outlet: OutletModel = {
  paginator: {} as Paginator,
  outlets: [],
  status: FetchStatus.LOADING,
  error: {},

  // Actions
  setPaginator: action((state, payload) => {
    state.paginator = payload
  }),
  setOutlets: action((state, payload) => {
    state.outlets = payload
  }),
  addOutlets: action((state, payload) => {
    state.outlets = [...state.outlets, ...payload]
  }),
  setStatus: action((state, payload) => {
    state.status = payload
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),

  // Thunks
  fetchAllOutlet: thunk((action, payload) => {
    action.setStatus(FetchStatus.LOADING)
    outletService
      .getAllOutlet(payload)
      .then(({ data, paginator }) => {
        action.setPaginator(paginator)
        action.setOutlets(data)
        action.setStatus(FetchStatus.LOADED)
        action.setError({})
      })
      .catch((e) => {
        action.setError(e)
        action.setStatus(FetchStatus.ERROR)
      })
  }),

  fetchMoreOutlet: thunk((action, payload) => {
    action.setStatus(FetchStatus.FETCHING_MORE)
    outletService
      .getAllOutlet(payload)
      .then(({ data, paginator }) => {
        action.setPaginator(paginator)
        action.addOutlets(data)
        action.setStatus(FetchStatus.LOADED)
        action.setError({})
      })
      .catch(() => {
        action.setStatus(FetchStatus.FETCHING_MORE_ERROR)
        action.setStatus(FetchStatus.LOADED)
      })
  })
}
