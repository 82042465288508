import { api } from '@utils'
import { CLIENT_CODE } from '@constants'

import { Client } from '.'

export const getAllClient = async (): Promise<Client[]> => {
  interface GetAllClientResponse {
    status: string
    status_code: number
    data: Client[]
  }

  try {
    const res = await api.get<GetAllClientResponse>(`/client-group/${CLIENT_CODE.slice(0, 2)}/client`)
    const { data } = res.data
    return data
  } catch (e) {
    throw e
  }
}

export const clientService = {
  getAllClient
}
