import { action, thunk } from 'easy-peasy'

import { FetchStatus } from '@types'
import { CLIENT_CODE } from '@constants'

import { clientService, ClientModel } from '.'

export const client: ClientModel = {
  selectedClient: null,
  clients: [],
  status: FetchStatus.LOADING,
  error: '',

  // Actions
  setSelectedClient: action((state, payload) => {
    state.selectedClient = payload
  }),
  setClients: action((state, payload) => {
    state.clients = payload
  }),
  setStatus: action((state, payload) => {
    state.status = payload
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),

  // Thunks
  fetchAllClient: thunk((action) => {
    action.setStatus(FetchStatus.LOADING)
    clientService
      .getAllClient()
      .then((clients) => {
        action.setStatus(FetchStatus.LOADED)
        action.setClients(clients)
        const client = clients.find(item => item.code === CLIENT_CODE)
        if (client) action.setSelectedClient(client)
        action.setError('')
      })
      .catch((e) => {
        action.setStatus(FetchStatus.ERROR)
        action.setError(e?.response?.data?.message || 'Network Error')
      })
  }),
}
